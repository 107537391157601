import React, { useState } from "react";
import { caafUsers } from "../components/Users";
import logo from "../logo-imprese.png";

const Login = ({ setadmin }) => {
  const [nomatch, setNomatcn] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = () => {
    const user = caafUsers.find(({ login }) => login === username);
    console.log(user); // { login: 'Admin', password: 'access, rule:"admin" }
    if (user) {
      if (user.password === password) {
        sessionStorage.admin = JSON.stringify(user);
        setadmin(user);
      }
    } else {
      setNomatcn(false);
    }
  };
  return (
    <div className="logindiv">
      <div style={{ maxWidth: "460px" }}>
        <h3 className="text-center fw-light">AREA RISERVATA</h3>
        <div className="card p-2 my-3">
          <div className="card-body">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control fw-bold"
                id="username"
                name="username"
                placeholder="username"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <label htmlFor="username">USERNAME</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                name="password"
                className="form-control fw-bold"
                id="floatingPassword"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="floatingPassword">PASSWORD</label>
            </div>
            <button
              onClick={() => handleSubmit()}
              className="btn btn-lg btn-secondary mt-3 w-100"
            >
              LOGIN
            </button>
            {!nomatch && (
              <p className="text-danger mt-3">Dati inseriti non validi</p>
            )}
          </div>
        </div>
      </div>
      <div className="text-center text-muted" style={{ maxWidth: "460px" }}>
        <img src={logo} alt="logo" style={{ maxWidth: "70%" }} />
        <br />
        <br />
        Via F. Paglia, 36
        <br />
        Tel 0961.061689
        <br />
        <span className="text-info fw-bold">Catanzaro</span>
      </div>
    </div>
  );
};

export default Login;
