import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { CFAssociatiArrContext } from "./components/Contexts";
import axios from "axios";
import "./App.css";
import Login from "./pages/Login";
import Persona from "./pages/Persona";
import Ditta from "./pages/Ditta";
import NotFund from "./pages/NotFund";
import Ditte from "./pages/Ditte";
import Persone from "./pages/Persone";
import Associati from "./pages/Associati";

import DittaPersone from "./pages/DittaPersone";
import ContactForm from "./pages/ContactForm";
import NavBar from "./components/NavBar";
import Pensionati from "./pages/Pensionati";
import Pensionato from "./pages/Pensionato";
import PensionatiProssimi from "./pages/PensionatiProssimi";
import RicercaDitte from "./pages/RicercaDitte";
import RicercaPersone from "./pages/RicercaPersone";
import RicercaPensionati from "./pages/RicercaPensionati";
import RicercaAssociati from "./pages/RicercaAssociati";

function App() {
  const [admin, setAdmin] = useState(
    sessionStorage.admin ? JSON.parse(sessionStorage.admin) : null
  );
  const [cfAss, setCfAss] = useState([]);

  useEffect(() => {
    const getcf = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/associati/cf`)
        .then((res) => {
          setCfAss(res.data);
        });
    };
    getcf();
  }, []);

  return !admin ? (
    <Login setadmin={setAdmin} />
  ) : (
    <CFAssociatiArrContext.Provider value={[cfAss, setCfAss]}>
      <NavBar setadmin={setAdmin} />
      <Routes>
        <Route path="/" element={<Ditte />} />
        <Route path="/ditta/:numiscr" element={<Ditta />} />
        <Route path="/ricercaditte" element={<RicercaDitte />} />
        <Route path="/persone" element={<Persone />} />
        <Route path="/ricercapersone" element={<RicercaPersone />} />
        <Route path="/persone/:numrea" element={<DittaPersone />} />
        <Route path="/persona/:id" element={<Persona />} />
        <Route path="/associati" element={<Associati />} />
        <Route path="/ricercaassociati" element={<RicercaAssociati />} />
        <Route path="/associato" element={<ContactForm />} />
        <Route path="/pensionati" element={<Pensionati />} />
        <Route path="/ricercapensionati" element={<RicercaPensionati />} />
        <Route path="/pensionato/:codfisc" element={<Pensionato />} />
        <Route path="/pensionatiprx" element={<PensionatiProssimi />} />
        <Route path="*" element={<NotFund to="/" />} />
      </Routes>
    </CFAssociatiArrContext.Provider>
  );
}

export default App;
