import React from "react";
import preload from "../Loading.gif";

const Loading = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "50vh" }}
    >
      <img src={preload} alt="preload" style={{ width: "80px" }} />
    </div>
  );
};

export default Loading;
