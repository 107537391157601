import React from "react";

const SearchBarAssociati = ({
	searchArrKeys,
	setSearchArrKeys,
	getAssociati,
}) => {
	function handleKeyCheck(e) {
		let searchKeys = { ...searchArrKeys };
		searchKeys[e.target.name] = e.target.value;
		if (searchKeys[e.target.name] === "") {
			// per non inviare elementi vuoti
			delete searchKeys[e.target.name];
		}
		setSearchArrKeys(searchKeys);
	}

	return (
		<div className="mb-5 p-3 bg-light border">
			<div className="container">
				<form>
					<div className="row gy-2 gx-3 align-items-end">
						<div className="col-auto">
							<label htmlFor="nominativo" className="form-label">
								NOME
							</label>
							<input
								type="text"
								className="form-control form-control-sm"
								id="nominativo"
								name="nominativo"
								onChange={handleKeyCheck}
							/>
						</div>
						<div className="col-auto">
							<label htmlFor="CODICEFISCALE" className="form-label">
								COD FISCALE
							</label>
							<input
								type="text"
								className="form-control form-control-sm"
								id="CODICEFISCALE"
								name="CODICEFISCALE"
								onChange={handleKeyCheck}
							/>
						</div>
						<div className="col-auto">
							<label htmlFor="LOCALITA" className="form-label">
								LOCALITA'
							</label>
							<input
								type="text"
								className="form-control form-control-sm"
								id="LOCALITA"
								name="LOCALITA"
								onChange={handleKeyCheck}
							/>
						</div>
						<div className="col-auto">
							<label htmlFor="ATECO_2007" className="form-label">
								COD ATECO
							</label>
							<input
								type="text"
								className="form-control form-control-sm"
								id="ATECO_2007"
								name="ATECO_2007"
								onChange={handleKeyCheck}
							/>
						</div>
						<div className="col-auto">
							<label htmlFor="SESSO" className="form-label">
								GENERE
							</label>
							<select
								className="form-select form-select-sm"
								name="SESSO"
								id="SESSO"
								onChange={handleKeyCheck}
							>
								<option selected></option>
								<option value="M">M</option>
								<option value="F">F</option>
							</select>
						</div>
						<div className="col-auto">
							<button
								className="btn btn-sm btn-secondary border-0 rounded-0 px-5 flex-auto mb-0"
								onClick={getAssociati}
							>
								<i className="bi bi-search"></i>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SearchBarAssociati;
