import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ElencoPersone from "../components/Persone/ElencoPersone";
import Loading from "../components/Loding";
import { CFAssociatiArrContext } from "../components/Contexts";
import GoBack from "../components/GoBack";

const DittaPersone = () => {
	const { numrea } = useParams();

	const [isLoading, setIsLoading] = useState(true);

	// read users
	const [persone, setpersone] = useState([]);

	// CF associati
	const [cfAss] = useContext(CFAssociatiArrContext);

	const checkAssociati = (cf) => {
		let handClass = "bi bi-hand-thumbs-down text-secondary";
		if (cfAss.includes(cf)) {
			handClass = "bi bi-hand-thumbs-up-fill text-danger";
		}
		return handClass;
	};

	useEffect(() => {
		const getpersone = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/_caaf_persone.php`, {
					params: {
						numrea,
					},
				})
				.then((res) => {
					setpersone(res.data.row);
					setIsLoading(false);
				});
		};
		getpersone();
	}, [numrea]);

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<div className="container">
					<ElencoPersone
						persone={persone}
						offset={50}
						checkAssociati={checkAssociati}
					/>
				</div>
			)}
			<GoBack />
		</>
	);
};

export default DittaPersone;
