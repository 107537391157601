import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loding";
import ElencoDitte from "../components/Ditte/ElencoDitte";
import SearchBar from "../components/SearchBar";
import Pagination from "../components/Pagination";

const Ditte = () => {
	const [isLoading, setIsLoading] = useState(true);

	const [query, setQuery] = useState("");
	const [searchKey, setSearchKey] = useState("NUM_ISCR");
	const [isSearch, setIsSearch] = useState(query !== "" ? true : false);

	const [ditte, setDitte] = useState([]);

	const searchArrKeys = [
		{ label: "Ragione Sociale", value: "RAGI_SOCI" },
		{ label: "Comune legale", value: "COMUNE_LEG" },
		{ label: "Numero Iscrizione", value: "NUM_ISCR" },
		{ label: "Codice ATECO", value: "ATECO_2002" },
		{ label: "Codice Fiscale", value: "COD_FISC" },
	];

	// pagination
	const [page, setPage] = useState(1);
	const perPage = 10;
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const maxPage = Math.ceil(totalRows / perPage);

	const handlePageClick = (action) => {
		if (action === "prev") {
			if (page > 1) {
				setPage(page - 1);
				setOffset((page - 2) * parseInt(perPage));
			}
		}
		if (action === "next") {
			if (page < maxPage) {
				setPage(page + 1);
				setOffset(page * parseInt(perPage));
			}
		}
	};

	useEffect(() => {
		const getDitte = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/_caaf_ditte.php`, {
					params: {
						k: searchKey,
						q: query,
						page,
						perpage: perPage,
						offset,
					},
				})
				.then((res) => {
					setDitte(res.data.rows);
					setTotalRows(res.data.totrows);
					setIsLoading(false);
				});
		};
		getDitte();
	}, [page, perPage, offset, query, searchKey]);

	return (
		<>
			<SearchBar
				setQuery={setQuery}
				searchKey={searchKey}
				searchArrKeys={searchArrKeys}
				setSearchKey={setSearchKey}
				searchlink="ricercaditte"
				setOffset={setOffset}
				setPage={setPage}
			/>
			{isLoading ? (
				<Loading />
			) : (
				<div className="container">
					<ElencoDitte ditte={ditte} offset={offset} />
					<Pagination
						page={page}
						handlePageClick={handlePageClick}
						totalRows={totalRows}
						maxPage={maxPage}
						isSearch={isSearch}
					/>
				</div>
			)}
		</>
	);
};

export default Ditte;
