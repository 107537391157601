import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import ElencoPersone from "../components/Persone/ElencoPersone";
import SearchBarPersone from "../components/SearchBarPersone";
import Pagination from "../components/Pagination";
import { CFAssociatiArrContext } from "../components/Contexts";

const RicercaPersone = () => {
	const [persone, setPersone] = useState([]);
	const [isSearch, setIsSearch] = useState(false);

	const [searchArrKeys, setSearchArrKeys] = useState({});

	// pagination
	const [page, setPage] = useState(1);
	const perPage = 10;
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const maxPage = Math.ceil(totalRows / perPage);

	const [cfAss] = useContext(CFAssociatiArrContext);

	const checkAssociati = (cf) => {
		let handClass = "bi bi-hand-thumbs-down text-secondary";
		if (cfAss.includes(cf)) {
			handClass = "bi bi-hand-thumbs-up-fill text-danger";
		}
		return handClass;
	};

	const handlePageClick = (action) => {
		if (action === "prev") {
			if (page > 1) {
				setPage(page - 1);
				setOffset((page - 2) * parseInt(perPage));
			}
		}
		if (action === "next") {
			if (page < maxPage) {
				setPage(page + 1);
				setOffset(page * parseInt(perPage));
			}
		}
	};

	function search() {
		axios
			.get(`${process.env.REACT_APP_API_URL}/_caaf_searchpersone.php`, {
				params: {
					searchArrKeys,
					page,
					perpage: perPage,
					offset,
				},
			})
			.then((res) => {
				setTotalRows(res.data.totrows);
				setPersone(res.data.rows);
			});
	}

	function getPersone(e) {
		e.preventDefault();
		setIsSearch(true);
		setOffset(0);
		setPage(1);
		search();
	}

	useEffect(() => {
		if (isSearch) {
			search();
		}
	}, [isSearch, page, search]);

	return (
		<>
			<SearchBarPersone
				searchArrKeys={searchArrKeys}
				setSearchArrKeys={setSearchArrKeys}
				getPersone={getPersone}
			/>
			{isSearch && (
				<div className="container">
					<ElencoPersone
						persone={persone}
						offset={offset}
						checkAssociati={checkAssociati}
					/>
					<Pagination
						page={page}
						handlePageClick={handlePageClick}
						totalRows={totalRows}
						maxPage={maxPage}
						isSearch={isSearch}
					/>
				</div>
			)}
		</>
	);
};

export default RicercaPersone;
