import React from "react";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
	const navigate = useNavigate();
	return (
		<div className="container text-end my-5 d-print-none">
			<button className="btn btn-secondary" onClick={() => navigate(-1)}>
				Go Back
			</button>
		</div>
	);
};

export default GoBack;
