import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loding";
import ElencoAssociati from "../components/Associati/ElencoAssociati";
import SearchBar from "../components/SearchBar";
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";

const Associati = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	const [query, setQuery] = useState("");
	const [searchKey, setSearchKey] = useState("COGNOME_NOME");
	const [isSearch, setIsSearch] = useState(query !== "" ? true : false);

	const [associati, setAssociati] = useState([]);

	const [csvAssociati, setCsvAssociati] = useState([]);

	const searchArrKeys = [
		{ label: "Nominativo", value: "COGNOME_NOME" },
		{ label: "Codice Fiscale", value: "CODICEFISCALE" },
		{ label: "Codice ATECO", value: "ATECO_2007" },
		{ label: "Genero", value: "SESSO" },
		{ label: "Località", value: "LOCALITA" },
	];

	// pagination
	const [page, setPage] = useState(1);
	const perPage = 10;
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const maxPage = Math.ceil(totalRows / perPage);

	const handlePageClick = (action) => {
		if (action === "prev") {
			if (page > 1) {
				setPage(page - 1);
				setOffset((page - 2) * parseInt(perPage));
			}
		}
		if (action === "next") {
			if (page < maxPage) {
				setPage(page + 1);
				setOffset(page * parseInt(perPage));
			}
		}
	};

	const handleFilterAss = (cf) => {
		const assObj = associati.filter(
			(associato) => associato.CODICEFISCALE === cf
		);
		console.log(assObj[0]); // and navigate to associato
		sessionStorage.setItem("associato", JSON.stringify(assObj[0]));
		navigate("/associato");
	};

	useEffect(() => {
		const getAssociati = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/_caaf_associati.php`, {
					params: {
						k: searchKey,
						q: query,
						page,
						perpage: perPage,
						offset,
					},
				})
				.then((res) => {
					setTotalRows(res.data.totrows);
					setAssociati(res.data.rows);
					setIsLoading(false);
				});
		};
		const getCsvAssociati = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/associati/csv`, {
					params: {
						k: searchKey,
						q: query,
					},
				})
				.then((res) => {
					setCsvAssociati(res.data);
				});
		};
		getAssociati();
		getCsvAssociati();
	}, [page, perPage, offset, query, searchKey]);

	return (
		<>
			<SearchBar
				setQuery={setQuery}
				searchKey={searchKey}
				searchArrKeys={searchArrKeys}
				setSearchKey={setSearchKey}
				searchlink="ricercaassociati"
				setOffset={setOffset}
				setPage={setPage}
			/>
			{isLoading ? (
				<Loading />
			) : (
				<div className="container">
					<ElencoAssociati
						associati={associati}
						offset={offset}
						handleFilterAss={handleFilterAss}
						csvAssociati={csvAssociati}
					/>
					<Pagination
						page={page}
						handlePageClick={handlePageClick}
						totalRows={totalRows}
						maxPage={maxPage}
						isSearch={isSearch}
					/>
				</div>
			)}
		</>
	);
};

export default Associati;
