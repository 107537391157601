import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({
  page,
  handlePageClick,
  totalRows,
  maxPage,
  isSearch,
}) => {
  return (
    <div className={`mt-3 mb-5 ${isSearch && "text-end"}`}>

      <nav>
        <ul className="pagination">
          <li className="page-item">
            <button
              className={`btn btn-secondary ${maxPage < 2 && "disabled"}`}
              onClick={() => handlePageClick("prev")}
            >
              <i className="bi bi-chevron-left"></i> INDIETRO
            </button>
          </li>
          <li className="page-item">
            <Link className="page-link text-dark border-0" to="#">
              {page}/{maxPage < 1 ? 1 : maxPage}{" "}
              <span className="badge rounded-pill bg-danger">
                {totalRows}
              </span>
            </Link>
          </li>
          <li className="page-item">
            <button
              className={`btn btn-secondary ${maxPage < 2 && "disabled"}`}
              onClick={() => handlePageClick("next")}
            >
              AVANTI <i className="bi bi-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
      {isSearch && (
        <a className="btn btn-secondary" href="/">
          DITTE
        </a>
      )}
    </div>
  );
};

export default Pagination;
