import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import GoBack from "../components/GoBack";

const ContactForm = () => {
	const navigate = useNavigate();
	const { cf } = useParams();
	//in formato italiano (data inglese).toLocaleDateString()
	const [associato] = useState(
		sessionStorage.associato ? JSON.parse(sessionStorage.associato) : {}
	);
	const [contact, setContact] = useState({
		contact_id: associato.contact_id || null,
		cod_fisc: associato.cod_fisc || cf,
		dataadesione:
			associato.dataadesione || new Date().toISOString().slice(0, 10),
		datarinnovo: associato.datarinnovo || new Date().toISOString().slice(0, 10),
		quota: associato.quota || "",
		email: associato.email || "",
		telefono: associato.telefono || "",
		note: associato.note || "",
	});
	const handleSubmit = (e) => {
		e.preventDefault();
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/_caaf_associati_add_up.php`,
				contact,
				{
					headers: {
						"content-type": "multipart/form-data",
					},
				}
			)
			.then(navigate("/associati"))
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const handleChange = (e) => {
		const dati = { ...contact };
		dati[e.target.name] = e.target.value;
		setContact(dati);
	};

	return (
		<>
			<div className="container my-5">
				<h1>
					<span className="text-secondary">
						{associato.COGNOME_NOME?.replace("/", " ")}
					</span>
				</h1>
				<h5>
					<span className="text-secondary">CF: {associato.CODICEFISCALE}</span>
				</h5>
				{associato.INDIRIZZO && (
					<p className="text-secondary">
						{associato.INDIRIZZO} - {associato.LOCALITA && associato.LOCALITA}
					</p>
				)}
			</div>
			<div className="container p-5 bg-light">
				<form onSubmit={handleSubmit}>
					<div className="row g-2 mb-3">
						<div className="col-md">
							<div className="form-floating">
								<input
									type="date"
									name="dataadesione"
									id="adesione"
									className="form-control fw-bold"
									placeholder="DATA ADESIONE"
									defaultValue={contact.dataadesione || ""}
									onChange={(e) => handleChange(e)}
								/>
								<label htmlFor="adesione">Data Adesione</label>
							</div>
						</div>
						<div className="col-md">
							<div className="form-floating">
								<input
									type="date"
									name="datarinnovo"
									id="rinnovo"
									className="form-control fw-bold"
									placeholder="DATA RINNOVO"
									defaultValue={contact.datarinnovo || ""}
									onChange={(e) => handleChange(e)}
								/>
								<label htmlFor="rinnovo">Data rinnovo</label>
							</div>
						</div>
						<div className="col-md">
							<div className="form-floating">
								<input
									type="text"
									name="quota"
									id="quota"
									className="form-control fw-bold"
									placeholder="QUOTA"
									defaultValue={contact.quota || ""}
									onChange={(e) => handleChange(e)}
								/>
								<label htmlFor="quota">Quota</label>
							</div>
						</div>
					</div>
					<div className="row g-2 mb-3">
						<div className="col-md">
							<div className="form-floating">
								<input
									type="email"
									name="email"
									id="email"
									placeholder="EMAIL"
									className="form-control fw-bold"
									defaultValue={contact.email || ""}
									onChange={(e) => handleChange(e)}
								/>
								<label htmlFor="email">Email</label>
							</div>
						</div>
						<div className="col-md">
							<div className="form-floating">
								<input
									type="text"
									name="telefono"
									id="telefono"
									className="form-control fw-bold"
									placeholder="TELEFONO"
									defaultValue={contact.telefono || ""}
									onChange={(e) => handleChange(e)}
								/>
								<label htmlFor="telefono">Telefono</label>
							</div>
						</div>
					</div>
					<div className="mb-3">
						<textarea
							name="note"
							id="note"
							rows="7"
							className="form-control"
							placeholder="Note"
							defaultValue={contact.note || ""}
							onChange={(e) => handleChange(e)}
						></textarea>
					</div>
					<div className="col">
						<button type="submit" className="btn btn-lg btn-secondary mb-3">
							SALVA
						</button>
					</div>
				</form>
			</div>
			<GoBack />
		</>
	);
};

export default ContactForm;
