import React, { useState, useEffect } from "react";
import axios from "axios";
import ElencoAssociati from "../components/Associati/ElencoAssociati";
import SearchBarAssociati from "../components/SearchBarAssociati";
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";

const RicercaAssociati = () => {
	const navigate = useNavigate();
	const [associati, setAssociati] = useState([]);
	const [csvAssociati, setCsvAssociati] = useState([]);
	const [isSearch, setIsSearch] = useState(false);

	const [searchArrKeys, setSearchArrKeys] = useState({});

	// pagination
	const [page, setPage] = useState(1);
	const perPage = 10;
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const maxPage = Math.ceil(totalRows / perPage);

	const handleFilterAss = (cf) => {
		const assObj = associati.filter(
			(associato) => associato.CODICEFISCALE === cf
		);
		console.log(assObj[0]); // and navigate to associato
		sessionStorage.setItem("associato", JSON.stringify(assObj[0]));
		navigate("/associato");
	};

	const handlePageClick = (action) => {
		if (action === "prev") {
			if (page > 1) {
				setPage(page - 1);
				setOffset((page - 2) * parseInt(perPage));
			}
		}
		if (action === "next") {
			if (page < maxPage) {
				setPage(page + 1);
				setOffset(page * parseInt(perPage));
			}
		}
	};

	function search() {
		axios
			.get(`${process.env.REACT_APP_API_URL}/_caaf_searchassociati.php`, {
				params: {
					searchArrKeys,
					page,
					perpage: perPage,
					offset,
				},
			})
			.then((res) => {
				console.log(res.data.csvrows);
				setTotalRows(res.data.totrows);
				setCsvAssociati(res.data.csvrows);
				setAssociati(res.data.rows);
			});
	}

	function getAssociati(e) {
		e.preventDefault();
		setIsSearch(true);
		setOffset(0);
		setPage(1);
		search();
	}

	useEffect(() => {
		if (isSearch) {
			search();
		}
	}, []);

	return (
		<>
			<SearchBarAssociati
				searchArrKeys={searchArrKeys}
				setSearchArrKeys={setSearchArrKeys}
				getAssociati={getAssociati}
			/>
			{isSearch && (
				<div className="container">
					<ElencoAssociati
						associati={associati}
						csvAssociati={csvAssociati}
						offset={offset}
						handleFilterAss={handleFilterAss}
					/>
					<Pagination
						page={page}
						handlePageClick={handlePageClick}
						totalRows={totalRows}
						maxPage={maxPage}
						isSearch={isSearch}
					/>
				</div>
			)}
		</>
	);
};

export default RicercaAssociati;
