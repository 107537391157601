import React from "react";
import { Link } from "react-router-dom";

const ElencoPersone = ({ persone, offset, checkAssociati }) => {
  return (
    <>
      <h2 className="mt-5">LISTA PERSONE</h2>
      {persone?.length ? (
        <div className="table-responsive mt-3">
          <table className="table table-hover my-3">
            <thead className="table-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">NOMINATIVO</th>
                <th scope="col">CODICE FISCALE</th>
                <th scope="col">COMUNE DI NASC</th>
                <th scope="col">D.TA NASC</th>
                <th scope="col">ETA'</th>
                <th scope="col">N.REA</th>
                <th scope="col">G</th>
                <th scope="col">ASS</th>
                <th scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {persone.map((persona, index) => (
                <tr key={index}>
                  <th scope="row">{offset + (index + 1)}</th>
                  <td>{persona.nominativo}</td>
                  <td>{persona.COD_FISCAL}</td>
                  <td>{persona.COMUNE_NAS}</td>
                  <td>{persona.DT_NASC}</td>
                  <td>
                    <span
                      className={`badge ${
                        persona.eta === 66 ? " bg-info text-dark" : " text-dark"
                      }`}
                    >
                      {persona.eta}
                    </span>
                  </td>
                  <td>{persona.NUMREA}</td>
                  <td>{persona.COD_SESSO}</td>
                  <td>
                    <i className={checkAssociati(persona.COD_FISCAL)}></i>
                  </td>
                  <td className="text-end" style={{ minWidth: "100px" }}>
                    <Link
                      to={`/persona/${persona.COD_FISCAL}`}
                      title="Dettagli"
                      className={`btn btn-sm btn-danger ${
                        persona.COD_FISCAL ? "" : "disabled"
                      }`}
                    >
                      <i className="bi bi-three-dots"></i>
                    </Link>

                    <Link
                      className="btn btn-sm btn-dark ms-3"
                      title="Ditta"
                      to={`/ditta/${persona.NUMREA}`}
                    >
                      <i className="bi bi-graph-up-arrow"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-info my-5" role="alert">
          Nessun dato trovato
        </div>
      )}
    </>
  );
};

export default ElencoPersone;
