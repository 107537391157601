import React from "react";
import { Link } from "react-router-dom";

const ElencoPensionati = ({ pensionati, offset }) => {
  return (
    <>
      <h2 className="mt-5">LISTA PENSIONATI</h2>
      {pensionati?.length ? (
        <div className="table-responsive mt-3">
          <table className="table table-hover my-3">
            <thead className="table-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">NOMINATIVO</th>
                <th scope="col">INDIRIZZO</th>
                <th scope="col">COMUNE</th>
                <th scope="col">PROV</th>
                <th scope="col">COD_FISC</th>
                <th scope="col">D.NASC</th>
                <th scope="col">G</th>
                <th scope="col" className="text-end">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {pensionati.map((pensionato, index) => (
                <tr key={index}>
                  <th scope="row">{offset + (index + 1)}</th>
                  <td>{pensionato.nominativo}</td>
                  <td>{pensionato.Indirizzo}</td>
                  <td>{pensionato.Comune}</td>
                  <td>{pensionato.Provincia}</td>
                  <td>{pensionato.CodiceFiscale}</td>
                  <td>{pensionato.DataNascita}</td>
                  <td>{pensionato.Sesso}</td>
                  <td className="text-end" style={{ minWidth: "100px" }}>
                    <Link
                      to={`/pensionato/${pensionato.CodiceFiscale}`}
                      title="Dettagli"
                      className={`btn btn-sm btn-danger ${
                        pensionato.CodiceFiscale ? "" : "disabled"
                      }`}
                    >
                      <i className="bi bi-three-dots"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-info my-5" role="alert">
          Nessun dato trovato
        </div>
      )}
    </>
  );
};

export default ElencoPensionati;
