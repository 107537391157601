import React from "react";

const SearchBarPensionati = ({
  searchArrKeys,
  setSearchArrKeys,
  getPensionati,
}) => {
  function handleKeyCheck(e) {
    let searchKeys = { ...searchArrKeys };
    searchKeys[e.target.name] = e.target.value;
    if (searchKeys[e.target.name] === "") {
      // per non inviare elementi vuoti
      delete searchKeys[e.target.name];
    }
    setSearchArrKeys(searchKeys);
  }
  return (
    <div className="mb-5 p-3 bg-light border">
      <div className="container">
        <form>
          <div className="row gy-2 gx-3 align-items-end">
            <div className="col-auto">
              <label htmlFor="nominativo" className="form-label">
                NOME
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="nominativo"
                name="nominativo"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="Comune" className="form-label">
                COMUNE
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="Comune"
                name="Comune"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="Provincia" className="form-label">
                PRV
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="Provincia"
                name="Provincia"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="Sesso" className="form-label">
                GENERE
              </label>
              <select
                className="form-select form-select-sm"
                name="Sesso"
                id="Sesso"
                onChange={handleKeyCheck}
              >
                <option selected></option>
                <option value="M">M</option>
                <option value="F">F</option>
              </select>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-sm btn-secondary border-0 rounded-0 px-5 flex-auto mb-0"
                onClick={getPensionati}
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBarPensionati;
