import React from "react";
import { Link } from "react-router-dom";

const ElencoDitte = ({ ditte, offset }) => {
  return (
    <>
      <h2>LISTA DITTE</h2>
      {ditte?.length ? (
        <div className="table-responsive">
          <table className="table table-hover my-3">
            <thead className="table-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">RAGI_SOCI</th>
                <th scope="col">COM LEGALE</th>
                <th scope="col">NUM_ISCR</th>
                <th scope="col">ATECO</th>
                <th scope="col">COD_FISC</th>
                <th scope="col" className="text-end">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {ditte.map((ditta, index) => (
                <tr key={index}>
                  <th scope="row">{offset + (index + 1)}</th>
                  <td>{ditta.RAGI_SOCI}</td>
                  <td>{ditta.COMUNE_LEG}</td>
                  <td>{ditta.NUM_ISCR}</td>
                  <td>{ditta.ATECO_2002}</td>
                  <td>{ditta.COD_FISC}</td>
                  <td className="text-end" style={{ minWidth: "100px" }}>
                    <Link
                      to={`/ditta/${ditta.NUM_ISCR}`}
                      className="btn btn-sm btn-danger"
                    >
                      <i className="bi bi-three-dots"></i>
                    </Link>

                    <Link
                      to={`/persone/${ditta.NUM_ISCR}`}
                      className="btn btn-sm btn-dark ms-3"
                    >
                      <i className="bi bi-person"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-info my-5" role="alert">
          Nessun dato trovato
        </div>
      )}
    </>
  );
};

export default ElencoDitte;
