import React from "react";
import { useParams } from "react-router-dom";
import GoBack from "../components/GoBack";

const Persona = () => {
  let { id } = useParams();
  return (
    <div className="container mt-5">
      <h2>{id}</h2>
      <GoBack />
    </div>
  );
};

export default Persona;
