import React from "react";

const SearchBarDitte = ({ searchArrKeys, setSearchArrKeys, getDitte }) => {
  function handleKeyCheck(e) {
    let searchKeys = { ...searchArrKeys };
    searchKeys[e.target.name] = e.target.value;
    if (searchKeys[e.target.name] === "") {
      // per non inviare elementi vuoti
      delete searchKeys[e.target.name];
    }
    setSearchArrKeys(searchKeys);
  }
  return (
    <div className="mb-5 p-3 bg-light border">
      <div className="container">
        <form>
          <div className="row gy-2 gx-3 align-items-end">
            <div className="col-auto">
              <label htmlFor="RAGI_SOCI" className="form-label">
                RAGI_SOCI
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="RAGI_SOCI"
                name="RAGI_SOCI"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="COMUNE_LEG" className="form-label">
                COMUNE_LEG
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="COMUNE_LEG"
                name="COMUNE_LEG"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="NUM_ISCR" className="form-label">
                NUM_ISCR
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="NUM_ISCR"
                name="NUM_ISCR"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="ATECO_2002" className="form-label">
                CODICE ATECO
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="ATECO_2002"
                name="ATECO_2002"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="COD_FISC" className="form-label">
                COD_FISC
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="COD_FISC"
                name="COD_FISC"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <button
                className="btn btn-sm btn-secondary border-0 rounded-0 px-5 flex-auto mb-0"
                onClick={getDitte}
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBarDitte;
