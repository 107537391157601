import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loding";
import ElencoPensionati from "../components/Pensionati/ElencoPensionati";
import SearchBar from "../components/SearchBar";
import Pagination from "../components/Pagination";

const Pensionati = () => {
	const [isLoading, setIsLoading] = useState(true);

	const [query, setQuery] = useState("");
	const [searchKey, setSearchKey] = useState("CodiceFiscale");
	const [isSearch, setIsSearch] = useState(query !== "" ? true : false);

	const [pensionati, setpensionati] = useState([]);

	const searchArrKeys = [
		{ label: "Nominativo", value: "nominativo" },
		{ label: "Codice Fiscale", value: "CodiceFiscale" },
		{ label: "Comune", value: "Comune" },
		{ label: "Genere", value: "Sesso" },
	];

	// pagination
	const [page, setPage] = useState(1);
	const perPage = 10;
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const maxPage = Math.ceil(totalRows / perPage);

	const handlePageClick = (action) => {
		if (action === "prev") {
			if (page > 1) {
				setPage(page - 1);
				setOffset((page - 2) * parseInt(perPage));
			}
		}
		if (action === "next") {
			if (page < maxPage) {
				setPage(page + 1);
				setOffset(page * parseInt(perPage));
			}
		}
	};

	useEffect(() => {
		const getpensionati = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/_caaf_pensionati.php`, {
					params: {
						k: searchKey,
						q: query,
						page,
						perpage: perPage,
						offset,
					},
				})
				.then((res) => {
					setpensionati(res.data.rows);
					setTotalRows(res.data.totrows);
					setIsLoading(false);
				});
		};
		searchKey !== "eta" && getpensionati();
	}, [page, perPage, offset, query, searchKey]);

	return (
		<>
			<SearchBar
				setQuery={setQuery}
				searchKey={searchKey}
				searchArrKeys={searchArrKeys}
				setSearchKey={setSearchKey}
				searchlink="ricercapensionati"
				setOffset={setOffset}
				setPage={setPage}
			/>
			{isLoading ? (
				<Loading />
			) : (
				<div className="container">
					<ElencoPensionati pensionati={pensionati} offset={offset} />
					<Pagination
						page={page}
						handlePageClick={handlePageClick}
						totalRows={totalRows}
						maxPage={maxPage}
						isSearch={isSearch}
					/>
				</div>
			)}
		</>
	);
};

export default Pensionati;
