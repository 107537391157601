import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GoBack from "../components/GoBack";
const Ditta = () => {
	let { numiscr } = useParams();
	const [ditta, setDitta] = useState({});
	useEffect(() => {
		const getDitta = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/_caaf_ditta.php`, {
					params: { numiscr },
				})
				.then((res) => setDitta(res.data.ditta));
		};
		getDitta();
	}, [numiscr]);
	return (
		<>
			{ditta && (
				<div className="container">
					<div className="card my-5">
						<div className="card-body">
							<h5 className="card-title">{ditta.RAGI_SOCI}</h5>
							<p className="card-text">
								{ditta.TOPUBI_LEG} {ditta.NOMVIA_LEG} {ditta.NUMVIA_LEG}
							</p>
							<p className="card-text">
								{ditta.CAP_LEG} {ditta.COMUNE_LEG} {ditta.SIGLA_LEG}
							</p>
						</div>
					</div>
				</div>
			)}
			<GoBack />
		</>
	);
};

export default Ditta;
