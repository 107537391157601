import React from "react";
import { CSVLink } from "react-csv";

const ElencoAssociati = ({
	associati,
	csvAssociati,
	offset,
	handleFilterAss,
}) => {
	//href={`${process.env.REACT_APP_API_URL}/contacts/csv`}
	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h2 className="mt-5">ELENCO ASSOCIATI</h2>

				{/* <a
            href={`${process.env.REACT_APP_API_URL}/contacts/csv`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-success"
          >
            SCARICA IL CSV
          </a> */}

				<CSVLink
					data={csvAssociati}
					separator={";"}
					filename={`${new Date().getTime()}`}
					className="btn btn-success"
				>
					SCARICA IL CSV
				</CSVLink>
			</div>
			{associati?.length ? (
				<div className="table-responsive mt-3">
					<table className="table table-hover my-3">
						<thead className="table-light">
							<tr>
								<th scope="col">#</th>
								<th scope="col">NOMINATIVO</th>
								<th scope="col">CODICEFISC</th>
								<th scope="col">LOCALITA'</th>
								<th scope="col">ATECO</th>
								<th scope="col">G</th>
								<th scope="col">TELEFONO</th>
								<th scope="col" className="text-end">
									ACTION
								</th>
							</tr>
						</thead>
						<tbody>
							{associati.map((associato, index) => (
								<tr key={index}>
									<th scope="row">{offset + (index + 1)}</th>
									<td>{associato.COGNOME_NOME.replace("/", " ")}</td>
									<td>{associato.CODICEFISCALE}</td>
									<td>{associato.LOCALITA}</td>
									<td>{associato.ATECO_2007}</td>
									<td>{associato.SESSO}</td>
									<td>{associato.telefono}</td>
									<td className="text-end" style={{ minWidth: "100px" }}>
										<button
											onClick={() => handleFilterAss(associato.CODICEFISCALE)}
											title="Dettagli"
											className="btn btn-sm btn-danger"
										>
											<i className="bi bi-person-lines-fill"></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="alert alert-info my-5" role="alert">
					Nessun dato trovato
				</div>
			)}
		</>
	);
};

export default ElencoAssociati;
