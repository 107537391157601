import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../LOGO_200x76.png";

const NavBar = ({ setadmin }) => {
	const logout = () => {
		sessionStorage.removeItem("admin");
		setadmin(null);
	};
	const [totalert, setTotalert] = useState(null);

	useEffect(() => {
		const getpenstoday = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/_caaf_pensionatiprx.php`)
				.then((res) => {
					setTotalert(res.data.rows.length);
				});
		};
		getpenstoday();
	}, []);
	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
			<div className="container-fluid">
				<NavLink className="navbar-brand" to="/">
					<img
						src={logo}
						alt="Logo"
						style={{ height: "50px", width: "auto" }}
					/>
				</NavLink>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarText"
					aria-controls="navbarText"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarText">
					<ul className="navbar-nav me-auto mb-2 mb-lg-0">
						<li className="nav-item">
							<NavLink className="nav-link" aria-current="page" to="/">
								Ditte
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/persone">
								Persone
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/associati">
								Associati
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/pensionati">
								Pensionati
							</NavLink>
						</li>
					</ul>
					{totalert && (
						<Link className="nav-link" to="/pensionatiprx">
							<span className="badge rounded-pill bg-info text-dark">
								{totalert}
							</span>
						</Link>
					)}
					<span className="navbar-text">
						{sessionStorage.admin && JSON.parse(sessionStorage.admin).login}
					</span>{" "}
					<button
						onClick={() => logout()}
						type="button"
						className="btn btn-link"
					>
						<i className="bi bi-box-arrow-right text-light"></i>
					</button>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
