import React, { useEffect, useState, useContext } from "react";
import { CFAssociatiArrContext } from "../components/Contexts";
import { Link } from "react-router-dom";
import axios from "axios";

const PensionatiProssimi = () => {
	const [cfAss] = useContext(CFAssociatiArrContext);

	const checkAssociati = (cf) => {
		let handClass = "bi bi-hand-thumbs-down text-secondary";
		if (cfAss.includes(cf)) {
			handClass = "bi bi-hand-thumbs-up-fill text-danger";
		}
		return handClass;
	};

	const [psxpensionati, setPsxpensionati] = useState([]);

	useEffect(() => {
		const getpensionati = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/_caaf_pensionatiprx.php`)
				.then((res) => {
					setPsxpensionati(res.data.rows);
				});
		};
		getpensionati();
	}, []);

	return (
		<>
			<div className="bg-light mb-5 p-3">
				<div className="container">
					<h3 className="text-secondary">
						Persone che hanno compiunto 66 anni oggi
					</h3>
				</div>
			</div>
			<div className="container">
				{psxpensionati?.length ? (
					<div className="table-responsive mt-3">
						<table className="table table-hover my-3">
							<thead className="table-light">
								<tr>
									<th scope="col">#</th>
									<th scope="col">NOMINATIVO</th>
									<th scope="col">CODICE FISCALE</th>
									<th scope="col">COMUNE DI NASC</th>
									<th scope="col">D.TA NASC</th>
									<th scope="col">ETA'</th>
									<th scope="col">N.REA</th>
									<th scope="col">G</th>
									<th scope="col">ASS</th>
									<th scope="col">ACTION</th>
								</tr>
							</thead>
							<tbody>
								{psxpensionati.map((persona, index) => (
									<tr key={index}>
										<th scope="row">{index + 1}</th>
										<td>{persona.nominativo}</td>
										<td>{persona.COD_FISCAL}</td>
										<td>{persona.COMUNE_NAS}</td>
										<td>{persona.DT_NASC}</td>
										<td>
											<span
												class={`badge ${
													persona.eta === 66 ? " bg-dark" : " bg-info"
												}`}
											>
												{persona.eta}
											</span>
										</td>
										<td>{persona.NUMREA}</td>
										<td>{persona.COD_SESSO}</td>
										<td>
											<i className={checkAssociati(persona.COD_FISCAL)}></i>
										</td>
										<td className="text-end" style={{ minWidth: "100px" }}>
											<Link
												to={`/persona/${persona.COD_FISCAL}`}
												title="Dettagli"
												className={`btn btn-sm btn-danger ${
													persona.COD_FISCAL ? "" : "disabled"
												}`}
											>
												<i className="bi bi-three-dots"></i>
											</Link>

											<Link
												className="btn btn-sm btn-dark ms-3"
												title="Ditta"
												to={`/ditta/${persona.NUMREA}`}
											>
												<i className="bi bi-graph-up-arrow"></i>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className="alert alert-info my-5" role="alert">
						Nessun dato trovato
					</div>
				)}
			</div>
		</>
	);
};

export default PensionatiProssimi;
