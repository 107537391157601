import React, { useState, useEffect } from "react";
import axios from "axios";
import ElencoPensionati from "../components/Pensionati/ElencoPensionati";
import SearchBarPensionati from "../components/SearchBarPensionati";
import Pagination from "../components/Pagination";

const RicercaPensionati = () => {
	const [pensionati, setPensionati] = useState([]);
	const [isSearch, setIsSearch] = useState(false);

	const [searchArrKeys, setSearchArrKeys] = useState({});

	// pagination
	const [page, setPage] = useState(1);
	const perPage = 10;
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const maxPage = Math.ceil(totalRows / perPage);

	const handlePageClick = (action) => {
		if (action === "prev") {
			if (page > 1) {
				setPage(page - 1);
				setOffset((page - 2) * parseInt(perPage));
			}
		}
		if (action === "next") {
			if (page < maxPage) {
				setPage(page + 1);
				setOffset(page * parseInt(perPage));
			}
		}
	};

	function search() {
		axios
			.get(`${process.env.REACT_APP_API_URL}/_caaf_searchpensionati.php`, {
				params: {
					searchArrKeys,
					page,
					perpage: perPage,
					offset,
				},
			})
			.then((res) => {
				setTotalRows(res.data.totrows);
				setPensionati(res.data.rows);
			});
	}

	function getPensionati(e) {
		e.preventDefault();
		setIsSearch(true);
		setOffset(0);
		setPage(1);
		search();
	}

	useEffect(() => {
		if (isSearch) {
			search();
		}
	}, [isSearch, page, search]);

	return (
		<>
			<SearchBarPensionati
				searchArrKeys={searchArrKeys}
				setSearchArrKeys={setSearchArrKeys}
				getPensionati={getPensionati}
			/>
			{isSearch && (
				<div className="container">
					<ElencoPensionati pensionati={pensionati} offset={offset} />
					<Pagination
						page={page}
						handlePageClick={handlePageClick}
						totalRows={totalRows}
						maxPage={maxPage}
						isSearch={isSearch}
					/>
				</div>
			)}
		</>
	);
};

export default RicercaPensionati;
