import React from "react";
import { useParams } from "react-router-dom";
import GoBack from "../components/GoBack";

const Pensionato = () => {
  const { codfisc } = useParams();
  return (
    <div className="container mt-5">
      <h2>{codfisc}</h2>
      <GoBack />
    </div>
  );
};

export default Pensionato;
