import React from "react";

const SearchBarPersone = ({ searchArrKeys, setSearchArrKeys, getPersone }) => {
  function handleKeyCheck(e) {
    let searchKeys = { ...searchArrKeys };
    searchKeys[e.target.name] = e.target.value;
    if (searchKeys[e.target.name] === "") {
      // per non inviare elementi vuoti
      delete searchKeys[e.target.name];
    }
    setSearchArrKeys(searchKeys);
  }
  return (
    <div className="mb-5 p-3 bg-light border">
      <div className="container">
        <form>
          <div className="row gy-2 gx-3 align-items-end">
            <div className="col-auto">
              <label htmlFor="nominativo" className="form-label">
                NOME
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="nominativo"
                name="nominativo"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="COMUNE_NAS" className="form-label">
                COMUNE_NAS
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="COMUNE_NAS"
                name="COMUNE_NAS"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="NUMREA" className="form-label">
                NUMREA
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="NUMREA"
                name="NUMREA"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="DT_NASC" className="form-label">
                CODICE ATECO
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="DT_NASC"
                name="DT_NASC"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="COD_SESSO" className="form-label">
                SESSO
              </label>
              <select
                className="form-select form-select-sm"
                name="COD_SESSO"
                id="COD_SESSO"
                onChange={handleKeyCheck}
              >
                <option selected></option>
                <option value="M">M</option>
                <option value="F">F</option>
              </select>
            </div>
            <div className="col-auto">
              <label htmlFor="eta" className="form-label">
                ETA'
              </label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="eta"
                name="eta"
                onChange={handleKeyCheck}
              />
            </div>
            <div className="col-auto">
              <button
                className="btn btn-sm btn-secondary border-0 rounded-0 px-5 flex-auto mb-0"
                onClick={getPersone}
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBarPersone;
