export const caafUsers = [
  {
    login: "admin",
    password: "access",
    rule: "admin",
  },
  {
    login: "User1",
    password: "pass1",
    rule: "user",
  },
  {
    login: "User2",
    password: "pass2",
    rule: "user",
  },
];
