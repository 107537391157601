import React from "react";
import { Link } from "react-router-dom";

const SearchBar = ({
	setOffset,
	setPage,
	setQuery,
	searchKey,
	searchArrKeys,
	setSearchKey,
	searchlink,
}) => {
	return (
		<div className="mb-5 p-3 bg-light border">
			<div className="container input-group">
				<input
					type="text"
					placeholder={`Cerca per ${searchKey.toLowerCase()}`}
					className="form-control form-control-lg rounded-0 flex-1"
					onChange={(e) => setQuery(e.target.value.trim())}
					onFocus={() => {
						setOffset(0);
						setPage(1);
					}}
				/>
				<div className="d-flex">
					<select
						className="form-select bg-light rounded-0 flex-auto lh-lg"
						value={searchKey}
						onChange={(e) => setSearchKey(e.target.value)}
						style={{ backgroundColor: "#E9ECEF" }}
					>
						{searchArrKeys.map((arrK, index) => (
							<option key={index} value={`${arrK.value}`}>
								{arrK.label.toUpperCase()}
							</option>
						))}
					</select>
					<Link
						to={`/${searchlink}`}
						className="btn btn-secondary btn-lg border-0 rounded-0 px-5 flex-auto"
					>
						<i className="bi bi-search"></i>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SearchBar;
