import React from "react";

const NotFund = () => {
  return (
    <div className="container text-center mt-5">
      <p className="display-1">Errore 404</p>
      <p className="display-5">Pagina non trovata</p>
    </div>
  );
};

export default NotFund;
